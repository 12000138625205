<template lang="pug">
div#ido-pei
  div.banner: img(src="@/assets/img/peipic.png")
  listBox(:objs="articles")/
</template>
<script>
import listBox from '@/components/article/list'
export default {
  components: {listBox},
  data(){return {
    articles: [],
    page: 1,
  }},
  methods: {
    getList(){
      this.$req.wlpList({page: this.page}, (res)=>{
        this.articles = res.list.map((a)=>{
          a.cate_id = 999
          a.atid = a.d_id
          return a
        })
      })
    },
  },
  mounted(){
    this.getList()
  }
}
</script>
<style lang="less">

</style>